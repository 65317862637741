@font-face {
  font-family: 'icons';
  src:
    url('fonts/icons.ttf?mr8iwv') format('truetype'),
    url('fonts/icons.woff?mr8iwv') format('woff'),
    url('fonts/icons.svg?mr8iwv#icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-circle-down:before {
  content: "\e93a";
}
.icon-circle-left:before {
  content: "\e93b";
}
.icon-circle-right:before {
  content: "\e93c";
}
.icon-circle-up:before {
  content: "\e93d";
}
.icon-behance:before {
  content: "\e939";
}
.icon-google:before {
  content: "\e938";
}
.icon-share:before {
  content: "\e934";
}
.icon-social:before {
  content: "\e934";
}
.icon-share1:before {
  content: "\e935";
}
.icon-out:before {
  content: "\e935";
}
.icon-external:before {
  content: "\e935";
}
.icon-outside:before {
  content: "\e935";
}
.icon-cross:before {
  content: "\e936";
}
.icon-cancel:before {
  content: "\e936";
}
.icon-close:before {
  content: "\e936";
}
.icon-quit:before {
  content: "\e936";
}
.icon-remove:before {
  content: "\e936";
}
.icon-blocked:before {
  content: "\e937";
}
.icon-forbidden:before {
  content: "\e937";
}
.icon-denied:before {
  content: "\e937";
}
.icon-banned:before {
  content: "\e937";
}
.icon-rss:before {
  content: "\ea9b";
}
.icon-youtube:before {
  content: "\ea9d";
}
.icon-vimeo:before {
  content: "\eaa0";
}
.icon-flickr2:before {
  content: "\eaa4";
}
.icon-pinterest:before {
  content: "\ead1";
}
.icon-event_busy:before {
  content: "\e932";
}
.icon-event_available:before {
  content: "\e933";
}
.icon-logo:before {
  content: "\e900";
}
.icon-logo-full:before {
  content: "\e901";
}
.icon-arr-left:before {
  content: "\e903";
}
.icon-arr-right:before {
  content: "\e904";
}
.icon-arr-up:before {
  content: "\e905";
}
.icon-arr-down:before {
  content: "\e906";
}
.icon-arrow-left:before {
  content: "\e907";
}
.icon-arrow-right:before {
  content: "\e908";
}
.icon-arrow-up:before {
  content: "\e909";
}
.icon-arrow-down:before {
  content: "\e90a";
}
.icon-arrow-long-left:before {
  content: "\e90b";
}
.icon-arrow-long-right:before {
  content: "\e90c";
}
.icon-arrow-long-up:before {
  content: "\e90d";
}
.icon-arrow-long-down:before {
  content: "\e90e";
}
.icon-close-thin:before {
  content: "\e927";
}
.icon-arr-horizontal:before {
  content: "\e90f";
}
.icon-arr-vertical:before {
  content: "\e910";
}
.icon-search:before {
  content: "\e911";
}
.icon-menu:before {
  content: "\e912";
}
.icon-setting:before {
  content: "\e925";
}
.icon-check:before {
  content: "\e913";
}
.icon-plus:before {
  content: "\e923";
}
.icon-minus:before {
  content: "\e924";
}
.icon-close1:before {
  content: "\e914";
}
.icon-new-window:before {
  content: "\e92a";
}
.icon-user:before {
  content: "\e902";
}
.icon-bell:before {
  content: "\e915";
}
.icon-book:before {
  content: "\e916";
}
.icon-briefcase:before {
  content: "\e917";
}
.icon-document:before {
  content: "\e918";
}
.icon-edit:before {
  content: "\e919";
}
.icon-print:before {
  content: "\e928";
}
.icon-envelope:before {
  content: "\e91a";
}
.icon-calendar:before {
  content: "\e926";
}
.icon-globe:before {
  content: "\e91b";
}
.icon-pen:before {
  content: "\e91c";
}
.icon-ruller:before {
  content: "\e91d";
}
.icon-phone:before {
  content: "\e92b";
}
.icon-flash:before {
  content: "\e92f";
}
.icon-notebook:before {
  content: "\e91e";
}
.icon-chat:before {
  content: "\e930";
}
.icon-publications:before {
  content: "\e92c";
}
.icon-publications-open:before {
  content: "\e931";
}
.icon-case:before {
  content: "\e92d";
}
.icon-tie:before {
  content: "\e92e";
}
.icon-instagram:before {
  content: "\e91f";
}
.icon-twitter:before {
  content: "\e920";
}
.icon-facebook:before {
  content: "\e921";
}
.icon-linkedin:before {
  content: "\e922";
}
.icon-googleplus:before {
  content: "\e929";
}
